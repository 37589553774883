import type { Article } from '$lib/domain/article.interface';
import type { BasicContent } from '$lib/domain/basic-content.interface';
import type { Review } from '$lib/domain/review.interface';
import type { Vacancy } from '$lib/domain/vacancy.interface';
import type { Blog } from '$lib/domain/blog.interface';
import type { Feedback } from '$lib/domain/feedback.interface';
import { ArticleCategoryEnum } from '$lib/domain/article-category.enum';
import { BlogCategoryEnum } from '$lib/domain/blog-category.enum';

export const blogs: Blog[] = [
	{
		title: 'Detailed guide on building enterprise app',
		url: '/detailed-guide-on-building-enterprise-app/',
		tags: [
			BlogCategoryEnum['Business app']
		],
		date: new Date(2023, 11, 29),
		author: 'ivan',
		relatedUrlList: [
			'/erp-for-construction-industry-and-infrastructure/',
			'/7-advantages-of-developing-user-personas-prior-to-app-development/',
			'/how-to-build-a-product-from-scratch/'
		]
	},
	{
		title: 'Technology for Elderly Care Services: a Purpose-Driven Approach Demystified',
		url: '/technology-for-elderly-care-services/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['AgeTech'],
			BlogCategoryEnum['Product development']
		],
		date: new Date(2023, 8, 28),
		author: 'oleg',
		relatedUrlList: [
			'/healthcare-of-the-future/',
			'/our-works/custom-telemedicine-solution/',
			'/our-works/medical-information-system-software/'
		]
	},
	{
		title: 'Basics of Developing On-Demand Service App',
		url: '/basics-of-developing-on-demand-service-app/',
		tags: [
			BlogCategoryEnum['Mobile']
		],
		date: new Date(2024, 2, 29),
		author: 'ivan',
		relatedUrlList: [
			'/how-to-build-a-product-from-scratch/',
			'/our-works/freelance-marketplace-development/',
			'/our-works/ev-charging-software/'
		]
	},
	{
		title: 'How to build a product from scratch',
		url: '/how-to-build-a-product-from-scratch/',
		tags: [
			BlogCategoryEnum['Outsourcing'],
			BlogCategoryEnum['Product development']
		],
		date: new Date(2024, 3, 13),
		author: 'oleg',
		relatedUrlList: [
			'/mvp-vs-mcp-what-to-choose-for-your-project/',
			'/our-works/custom-telemedicine-solution/',
			'/our-works/freelance-marketplace-development/'
		]
	},
	{
		title: 'How to Implement GPT into Your App',
		url: '/how-to-implement-gpt-into-your-app/',
		tags: [
			BlogCategoryEnum['Mobile']
		],
		date: new Date(2023, 4, 12),
		author: 'ivan',
		relatedUrlList: [
			'/how-to-create-a-messaging-app-like-whatsapp/',
			'/our-works/travel-management-mobile-app/',
			'/our-works/land-tax-software/'
		]
	},
	{
		title: 'Medtech Trends to Watch in 2024',
		url: '/medtech-trends/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Trends']
		],
		date: new Date(2023, 3, 1),
		author: 'oleg',
		relatedUrlList: [
			'/our-works/custom-telemedicine-solution/',
			'/our-works/health-metrics/'
		]
	},
	{
		title: 'What is SaMD? All About Software as a Medical Device',
		url: '/what-is-samd-all-about-software-as-a-medical-device/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['SaMD']
		],
		date: new Date(2023, 2, 6),
		author: 'oleg',
		relatedUrlList: [
			'/healthcare-mobile-app-trends/',
			'/our-works/lab-web-portal/',
			'/our-works/oil-and-gas-data-management-software/'
		]
	},
	{
		title: '7 Advantages of Developing User Personas Prior to App Development',
		url: '/7-advantages-of-developing-user-personas-prior-to-app-development/',
		tags: [
			BlogCategoryEnum['UI/UX Design'],
			BlogCategoryEnum['Design'],
		],
		date: new Date(2022, 10, 17),
		author: 'ivan',
		relatedUrlList: [
			'/our-works/travel-management-mobile-app/',
			'/how-to-build-a-mobile-banking-app/'
		]
	},
	{
		title: 'ERP for Construction Industry and Infrastructure',
		url: '/erp-for-construction-industry-and-infrastructure/',
		tags: [
			BlogCategoryEnum['Construction'],
			BlogCategoryEnum['Business app'],
			BlogCategoryEnum['ERP']
		],
		date: new Date(2024, 3, 19),
		author: 'oleg',
		relatedUrlList: [
			'/what-is-warehouse-management-system/',
			'/our-works/mind-your-service/',
			'/our-works/optimizing-trucking-logistics/'
		]
	},
	{
		title: 'How to Build a Mobile Banking App',
		url: '/how-to-build-a-mobile-banking-app/',
		tags: [
			BlogCategoryEnum['Finance'],
			BlogCategoryEnum['Mobile']
		],
		date: new Date(2024, 3, 7),
		author: 'ivan',
		relatedUrlList: [
			'/7-advantages-of-developing-user-personas-prior-to-app-development/',
			'/our-works/road-trip-mobile-app/',
			'/our-works/mind-your-service/'
		]
	},
	{
		title: 'Software Outsourcing to Ukraine: Reasons to Hire Ukrainian Developers',
		url: '/software-outsourcing-ukraine-hire-software-developers/',
		tags: [
			BlogCategoryEnum['Outsourcing'],
			BlogCategoryEnum['Team Extension']
		],
		date: new Date(2022, 6, 24),
		author: 'ivan',
		relatedUrlList: [
			'/mvp-vs-mcp-what-to-choose-for-your-project/',
			'/our-works/mind-your-service-mobile-gps-tracker/',
			'/our-works/custom-telemedicine-solution/'
		]
	},
	{
		title: 'What Can You Do with Java and What is it Used for?',
		url: '/what-can-you-do-with-java/',
		tags: [
			BlogCategoryEnum['Technology'],
			BlogCategoryEnum['Java']
		],
		date: new Date(2022, 6, 16),
		author: 'ivan',
		relatedUrlList: [
			'/hipaa-compliant-telehealth-platform/',
			'/our-works/network-deposition-services/',
			'/our-works/oil-and-gas-data-management-software/'
		]
	},
	{
		title: '.NET vs Node.js: What to Choose',
		url: '/net-vs-node-js/',
		tags: [
			BlogCategoryEnum['Technology']
		],
		date: new Date(2021, 11, 17),
		author: 'ivan',
		relatedUrlList: [
			'/our-works/freelance-marketplace-development/',
			'/our-works/network-deposition-services/'
		]
	},
	{
		title: 'Healthcare Website Design: 15 Examples & Best Practices',
		url: '/healthcare-website-design/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Web'],
			BlogCategoryEnum['UI/UX Design'],
			BlogCategoryEnum['Design']
		],
		date: new Date(2024, 2, 20),
		author: 'oleg',
		relatedUrlList: ['/what-is-a-lims/', '/healthcare-mobile-app-trends/']
	},
	{
		title: 'What is a LIMS? What does LIMS stand for?',
		url: '/what-is-a-lims/',
		tags: [
			BlogCategoryEnum['Business app'],
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['LIMS']
		],
		date: new Date(2024, 4, 5),
		author: 'ivan',
		relatedUrlList: ['/our-works/scom-health-check-v2/', '/healthcare-app-development-process/']
	},
	{
		title: 'Warehouse Management: Definition, Process, Practices',
		url: '/what-is-warehouse-management-system/',
		tags: [
			BlogCategoryEnum['Logistics'],
			BlogCategoryEnum['Warehouse management']
		],
		date: new Date(2024, 4, 4),
		author: 'ivan',
		relatedUrlList: ['/our-works/lab-web-portal/', '/our-works/oil-and-gas-data-management-software/']
	},
	{
		title: 'Logistics Management Software',
		url: '/best-logistics-software/',
		tags: [
			BlogCategoryEnum['Logistics']
		],
		date: new Date(2024, 3, 28),
		author: 'oleg',
		relatedUrlList: [
			'/erp-for-construction-industry-and-infrastructure/',
			'/our-works/optimizing-trucking-logistics/',
			'/our-works/construction-supply-chain-automation/'
		]
	},
	{
		title: 'Software Development Methodologies with Pros and Cons',
		url: '/software-development-methodologies-pros-cons/',
		tags: [
			BlogCategoryEnum['Technology']
		],
		date: new Date(2021, 4, 28),
		author: 'ivan',
		relatedUrlList: ['/our-works/outdoor-advertising-management-software/', '/our-works/travel-management-mobile-app/']
	},
	{
		title: 'How to Make Your Telemedicine Platform HIPAA-Compliant',
		url: '/hipaa-compliant-telehealth-platform/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Telemedicine']
		],
		date: new Date(2024, 4, 10),
		author: 'oleg',
		relatedUrlList: [
			'/healthcare-of-the-future/',
			'/healthcare-app-development-process/',
			'/ehr-system-development-process/'
		]
	},
	{
		title: 'Pros and Cons of Xamarin App Development',
		url: '/pros-and-cons-of-xamarin-app-development/',
		tags: [
			BlogCategoryEnum['Mobile'],
			BlogCategoryEnum['Xamarin']
		],
		date: new Date(2021, 2, 21),
		author: 'ivan',
		relatedUrlList: [
			'/how-to-build-a-mobile-banking-app/',
			'/our-works/travel-management-mobile-app/',
			'/our-works/land-tax-software/'
		]
	},
	{
		title: 'Functional and Non-functional Requirements: Key Differences',
		url: '/functional-non-functional-requirements/',
		tags: [
			BlogCategoryEnum['Technology']
		],
		date: new Date(2021, 2, 11),
		author: 'ivan',
		relatedUrlList: [
			'/erp-for-construction-industry-and-infrastructure/',
			'/our-works/lab-web-portal/',
			'/our-works/medical-information-system-software/'
		]
	},
	{
		title: 'Mobile Application Architecture: Best Practices',
		url: '/mobile-app-architecture/',
		tags: [
			BlogCategoryEnum['Mobile']
		],
		date: new Date(2021, 2, 9),
		author: 'ivan',
		relatedUrlList: [
			'/what-can-you-do-with-java/',
			'/our-works/land-tax-software/',
			'/our-works/road-trip-mobile-app/'
		]
	},
	{
		title: 'System Development Life Cycle (SDLC): Phases, Models, and Benefits',
		url: '/system-development-life-cycle/',
		tags: [
			BlogCategoryEnum['Technology']
		],
		date: new Date(2024, 2, 2),
		author: 'ivan',
		relatedUrlList: [
			'/our-works/construction-supply-chain-automation/',
			'/our-works/travel-management-mobile-app/'
		]
	},
	{
		title: 'Web Application Architecture: Types, Components, and Trends',
		url: '/web-app-architecture-types-components-trends/',
		tags: [
			BlogCategoryEnum['Web']
		],
		date: new Date(2021, 1, 27),
		author: 'ivan',
		relatedUrlList: [
			'/7-advantages-of-developing-user-personas-prior-to-app-development/',
			'/software-development-methodologies-pros-cons/',
			'/our-works/freelance-marketplace-development/'
		]
	},
	{
		title: 'Healthcare App Trends You Need to Follow',
		url: '/healthcare-mobile-app-trends/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Mobile'],
			BlogCategoryEnum['Trends']
		],
		date: new Date(2021, 1, 25),
		author: 'oleg',
		relatedUrlList: [
			'/hipaa-compliant-telehealth-platform/',
			'/our-works/health-metrics/',
			'/our-works/custom-telemedicine-solution/'
		]
	},
	{
		title: 'How to Create a Messaging App Like WhatsApp',
		url: '/how-to-create-a-messaging-app-like-whatsapp/',
		tags: [
			BlogCategoryEnum['Mobile'],
			BlogCategoryEnum['Media & Entertainment']
		],
		date: new Date(2021, 1, 22),
		author: 'ivan',
		relatedUrlList: ['/our-works/quarry-automation-platform/', '/our-works/health-metrics/']
	},
	{
		title: 'Benefits of Telehealth for Patients and Doctors',
		url: '/benefits-of-telehealth-for-patients-doctors/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Telemedicine'],
			BlogCategoryEnum['Mobile']
		],
		date: new Date(2024, 5, 27),
		author: 'oleg',
		relatedUrlList: [
			'/hipaa-compliant-telehealth-platform/',
			'/our-works/medical-information-system-software/',
			'/our-works/custom-telemedicine-solution/'
		]
	},
	{
		title: 'How to Create a GPS App',
		url: '/make-gps-app-android-ios/',
		tags: [
			BlogCategoryEnum['Mobile']
		],
		date: new Date(2021, 1, 14),
		author: 'ivan',
		relatedUrlList: [
			'/how-to-create-a-messaging-app-like-whatsapp/',
			'/our-works/road-trip-mobile-app/',
			'/our-works/travel-management-mobile-app/'
		]
	},
	{
		title: 'Angular Development: Pros and Cons',
		url: '/angular-pros-and-cons/',
		tags: [
			BlogCategoryEnum['Technology']
		],
		date: new Date(2021, 1, 13),
		author: 'ivan',
		relatedUrlList: [
			'/our-works/construction-supply-chain-automation/',
			'/our-works/bridgestone-car-park-survey/'
		]
	},
	{
		title: 'The Role of a CTO in a Startup. Mission, Responsibilities, and Skills',
		url: '/the-role-of-a-cto-in-a-startup-mission-responsibilities-and-skills/',
		tags: [
			BlogCategoryEnum['Outsourcing']
		],
		date: new Date(2019, 9, 30),
		author: 'ivan',
		relatedUrlList: [
			'/development-team-for-startup/',
			'/our-works/travel-management-mobile-app/',
			'/our-works/medical-information-system-software/'
		]
	},
	{
		title: 'How Uber Freight Is Changing the Freight Forwarding Market',
		url: '/how-uber-freight-is-changing-the-freight-forwarding-market/',
		tags: [
			BlogCategoryEnum['Logistics'],
			BlogCategoryEnum['Freight Forwarding']
		],
		date: new Date(2019, 9, 16),
		author: 'ivan',
		relatedUrlList: [
			'/best-logistics-software/',
			'/our-works/mind-your-service-mobile-gps-tracker/',
			'/our-works/outdoor-advertising-management-software/'
		]
	},
	{
		title: 'MVP vs MCP: What to Choose for Your Project?',
		url: '/mvp-vs-mcp-what-to-choose-for-your-project/',
		tags: [
			BlogCategoryEnum['Outsourcing'],
			BlogCategoryEnum['Product development'],
			BlogCategoryEnum['MVP'],
		],
		date: new Date(2024, 1, 8),
		author: 'ivan',
		relatedUrlList: [
			'/what-is-warehouse-management-system/',
			'/our-works/bridgestone-car-park-survey/',
			'/our-works/emergency-notifier/'
		]
	},
	{
		title: 'Why a Fixed-Price Contract Is a Bad Idea for Your Project?',
		url: '/why-a-fixed-price-contract-is-a-bad-idea-for-your-project/',
		tags: [
			BlogCategoryEnum['Outsourcing'],
			BlogCategoryEnum['Team Extension']
		],
		date: new Date(2019, 8, 8),
		author: 'ivan',
		relatedUrlList: [
			'/what-is-warehouse-management-system/',
			'/our-works/quarry-automation-platform/'
		]
	},
	{
		title:
			'Time and Materials vs Fixed Price vs Scope Based Model: Choosing the Right Pricing Model for Your Project',
		url: '/time-and-materials-vs-fixed-price-vs-scope-based-model-choosing-the-right-pricing-model-for-your-project/',
		tags: [
			BlogCategoryEnum['Outsourcing']
		],
		date: new Date(2019, 7, 29),
		author: 'ivan',
		relatedUrlList: [
			'/best-logistics-software/',
			'/our-works/construction-supply-chain-automation/',
			'/our-works/ev-charging-software/'
		]
	},
	{
		title: 'Top 6 Logistics Startups to Watch Out',
		url: '/top-6-logistics-startups-to-watch-out/',
		tags: [
			BlogCategoryEnum['Logistics']
		],
		date: new Date(2019, 7, 22),
		author: 'oleg',
		relatedUrlList: [
			'/best-logistics-software/',
			'/our-works/optimizing-trucking-logistics/',
			'/our-works/scom-health-check-v2/'
		]
	},
	{
		title: 'Why Every Logistics Business Must Go Mobile',
		url: '/why-every-logistics-business-must-go-mobile/',
		tags: [
			BlogCategoryEnum['Logistics'],
			BlogCategoryEnum['Mobile']
		],
		date: new Date(2024, 4, 23),
		author: 'oleg',
		relatedUrlList: [
			'/development-team-for-startup/',
			'/our-works/optimizing-trucking-logistics/',
			'/top-6-logistics-startups-to-watch-out/'
		]
	},
	{
		title: 'How to Make Agile and Outsourcing Work?',
		url: '/how-to-make-agile-and-outsourcing-work/',
		tags: [
			BlogCategoryEnum['Outsourcing'],
			BlogCategoryEnum['Team Extension']
		],
		date: new Date(2024, 4, 5),
		author: 'ivan',
		relatedUrlList: [
			'/software-outsourcing-ukraine-hire-software-developers/',
			'/our-works/construction-supply-chain-automation/',
			'/our-works/agriculture-software-solutions/'
		]
	},
	{
		title: '6 Tips for Managing an Outsourced Team',
		url: '/6-tips-for-managing-an-outsourced-team/',
		tags: [
			BlogCategoryEnum['Outsourcing'],
			BlogCategoryEnum['Team Extension']
		],
		date: new Date(2017, 7, 19),
		author: 'ivan',
		relatedUrlList: [
			'/software-outsourcing-ukraine-hire-software-developers/',
			'/best-logistics-software/',
			'/our-works/optimizing-trucking-logistics/'
		]
	},
	{
		title: 'Make Entity Framework 10x faster in 20 minutes',
		url: '/make-entity-framework-10x-faster-in-20-minutes/',
		tags: [
			BlogCategoryEnum['Technology']
		],
		date: new Date(2017, 3, 9),
		author: 'ivan',
		relatedUrlList: [
			'/what-can-you-do-with-java/',
			'/our-works/custom-telemedicine-solution/',
			'/our-works/quarry-automation-platform/'
		]
	},
	{
		title: 'Healthcare mobile apps which make a change',
		url: '/healthcare-mobile-apps-which-make-a-change/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Mobile']
		],
		date: new Date(2024, 2, 14),
		author: 'oleg',
		relatedUrlList: [
			'/hipaa-compliant-telehealth-platform/',
			'/our-works/custom-telemedicine-solution/',
			'/our-works/medical-information-system-software/'
		]
	},
	{
		title: 'Top 9 wireframing and prototyping tools',
		url: '/top-9-wireframing-and-prototyping-tools/',
		tags: [
			BlogCategoryEnum['Technology']
		],
		date: new Date(2017, 2, 15),
		author: 'ivan',
		relatedUrlList: [
			'/ui-ux-design-services/',
			'/our-works/custom-telemedicine-solution/',
			'/our-works/construction-supply-chain-automation/'
		]
	},
	{
		title: 'What you need to know about building MVP',
		url: '/what-you-need-to-know-about-building-mvp/',
		tags: [
			BlogCategoryEnum['Outsourcing'],
			BlogCategoryEnum['MVP'],
			BlogCategoryEnum['Product development']
		],
		date: new Date(2024, 1, 30),
		author: 'ivan',
		relatedUrlList: [
			'/our-works/travel-management-mobile-app/',
			'/mvp-vs-mcp-what-to-choose-for-your-project/',
			'/our-works/mind-your-service-mobile-gps-tracker/'
		]
	},
	{
		title: 'Desktop or Web Application: What to Develop',
		url: '/desktop-or-web-application-what-to-develop/',
		tags: [
			BlogCategoryEnum['Web']
		],
		date: new Date(2017, 1, 11),
		author: 'ivan',
		relatedUrlList: [
			'/our-works/medical-information-system-software/',
			'/our-works/land-tax-software/'
		]
	},
	{
		title: 'Design thinking approach in software development',
		url: '/design-thinking-approach-in-software-development/',
		tags: [
			BlogCategoryEnum['UI/UX Design'],
			BlogCategoryEnum['Design']
		],
		date: new Date(2016, 12, 13),
		author: 'ivan',
		relatedUrlList: [
			'/web-app-architecture-types-components-trends/',
			'/our-works/mind-your-service/',
			'/our-works/formula-compounder/'
		]
	},
	{
		title: '10 Lightweight Bootstrap Alternatives',
		url: '/10-lightweight-bootstrap-alternatives/',
		tags: [
			BlogCategoryEnum['Technology']
		],
		date: new Date(2016, 12, 6),
		author: 'ivan',
		relatedUrlList: [
			'/7-advantages-of-developing-user-personas-prior-to-app-development/',
			'/our-works/mind-your-service/',
			'/our-works/bridgestone-car-park-survey/'
		]
	},
	{
		title: 'Choosing a Development Team for a Startup: In-house or Outsourced?',
		url: '/development-team-for-startup/',
		tags: [
			BlogCategoryEnum['Outsourcing'],
			BlogCategoryEnum['Team Extension']
		],
		date: new Date(2016, 10, 27),
		author: 'ivan',
		relatedUrlList: [
			'/development-team-for-startup/',
			'/our-works/travel-management-mobile-app/',
			'/our-works/land-tax-software/'
		]
	},
	{
		title: 'How to Build a Healthcare App: A Complete Guide',
		url: '/healthcare-app-development-process/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Mobile']
		],
		date: new Date(2024, 1, 4),
		author: 'oleg',
		relatedUrlList: [
			'/healthcare-of-the-future/',
			'/technology-for-elderly-care-services/',
			'/hipaa-compliant-telehealth-platform/'
		]
	},
	{
		title: 'How to Build an EHR System: A Complete Guide',
		url: '/ehr-system-development-process/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Business app'],
			BlogCategoryEnum['EHR/EMR']
		],
		date: new Date(2023, 12, 15),
		author: 'ivan',
		relatedUrlList: [
			'/healthcare-mobile-app-trends/',
			'/healthcare-of-the-future/',
			'/medtech-trends/'
		]
	},
	{
		title: 'Healthcare of the Future: Connecting Patients with Doctors',
		url: '/healthcare-of-the-future/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Trends']
		],
		date: new Date(2023, 8, 1),
		author: 'oleg',
		relatedUrlList: [
			'/our-works/scom-health-check-v2/',
			'/medtech-trends/',
			'/benefits-of-telehealth-for-patients-doctors/'
		]
	},
	{
		title: 'An In-Depth Guide to Trucking App Development',
		url: '/trucking-app-development-guide/',
		tags: [
			BlogCategoryEnum['Logistics'],
			BlogCategoryEnum['Mobile']
		],
		date: new Date(2023, 12, 20),
		author: 'ivan',
		relatedUrlList: ['/how-to-build-a-product-from-scratch/']
	},
	{
		title: 'Dynamic Route Planning & Optimization in Logistics',
		url: '/dynamic-route-planning-and-optimization-explained/',
		tags: [
			BlogCategoryEnum['Logistics']
		],
		date: new Date(2024, 1, 24),
		author: 'ivan',
		relatedUrlList: [
			'/trucking-app-development-guide/',
			'/make-gps-app-android-ios/',
			'/supply-chain-management-software-features-requirements/'
		]
	},
	{
		title: 'Everything You Need to Know Before You Start Developing a Pharmacy App',
		url: '/pharmacy-app-development-process/',
		tags: [
			BlogCategoryEnum['Pharma'],
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Mobile']
		],
		date: new Date(2024, 2, 6),
		author: 'oleg',
		relatedUrlList: [
			'/healthcare-of-the-future/',
			'/our-works/custom-telemedicine-solution/',
			'/healthcare-app-development-process/'
		]
	},
	{
		title: 'Building a Fleet Management App: Essential Features and Development Process',
		url: '/fleet-management-app-development/',
		tags: [
			BlogCategoryEnum['Logistics'],
			BlogCategoryEnum['Mobile'],
			BlogCategoryEnum['Fleet management']
		],
		date: new Date(2024, 2, 15),
		author: 'ivan',
		relatedUrlList: [
			'/supply-chain-management-software-features-requirements/',
			'/why-every-logistics-business-must-go-mobile/',
			'/dynamic-route-planning-and-optimization-explained/'
		]
	},
	{
		title: 'Last-Mile Delivery Technology: Benefits, Application, Best Practices',
		url: '/what-is-last-mile-delivery-technology/',
		tags: [
			BlogCategoryEnum['Logistics'],
			BlogCategoryEnum['Last-mile delivery']
		],
		date: new Date(2024, 2, 19),
		author: 'ivan',
		relatedUrlList: [
			'/trucking-app-development-guide/',
			'/dynamic-route-planning-and-optimization-explained/',
			'/top-6-logistics-startups-to-watch-out/'
		]
	},
	{
		title: 'How to Develop a Construction App: A Deep Dive',
		url: '/construction-app-development-guide/',
		tags: [
			BlogCategoryEnum['Construction'],
			BlogCategoryEnum['Mobile']
		],
		date: new Date(2024, 3, 12),
		author: 'ivan',
		relatedUrlList: [
			'/our-works/construction-supply-chain-automation/',
			'/detailed-guide-on-building-enterprise-app/',
			'/erp-for-construction-industry-and-infrastructure/'
		]
	},
	{
		title: 'Top 5 Supply Chain Management Software Requirements',
		url: '/supply-chain-management-software-features-requirements/',
		tags: [
			BlogCategoryEnum['Logistics'],
			BlogCategoryEnum['Supply chain management']
		],
		date: new Date(2024, 3, 21),
		author: 'ivan',
		relatedUrlList: [
			'/why-every-logistics-business-must-go-mobile/',
			'/trucking-app-development-guide/',
			'/dynamic-route-planning-and-optimization-explained/'
		]
	},
	{
		title: '10 New Technologies in the Construction Industry to Watch in 2024 and Beyond',
		url: '/construction-industry-technology-trends/',
		tags: [
			BlogCategoryEnum['Construction'],
			BlogCategoryEnum['Trends'],
		],
		date: new Date(2024, 4, 24),
		author: 'oleg',
		relatedUrlList: [
			'/construction-app-development-guide/',
			'/detailed-guide-on-building-enterprise-app/',
			'/our-works/construction-supply-chain-automation/'
		]
	},
	{
		title: 'What is EHR in Healthcare and How it Improves Patient Care',
		url: '/ehr-for-patient-care/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Business app'],
			BlogCategoryEnum['EHR/EMR']
		],
		date: new Date(2024, 5, 1),
		author: 'oleg',
		relatedUrlList: [
			'/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
			'/ehr-system-development-process/',
			'/our-works/medical-information-system-software/'
		]
	},
	{
		title: 'How to Create a LIMS System: 5 Steps Guide',
		url: '/how-to-create-laboratory-information-management-system/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Business app'],
			BlogCategoryEnum['LIMS']
		],
		date: new Date(2024, 5, 3),
		author: 'ivan',
		relatedUrlList: [
			'/what-is-a-lims/',
			'/our-works/formula-compounder/',
			'/our-works/medical-information-system-software/'
		]
	},
	{
		title: '10 Proven Strategies for Optimizing Supply Chain Efficiency',
		url: '/supply-chain-improvement-guide/',
		tags: [
			BlogCategoryEnum['Logistics'],
			BlogCategoryEnum['Supply chain management']
		],
		date: new Date(2024, 5, 31),
		author: 'ivan',
		relatedUrlList: [
			'/our-works/construction-supply-chain-automation/',
			'/supply-chain-management-software-features-requirements/',
			'/best-logistics-software/'
		]
	},
	{
		title: '7 Warehouse Optimization Tips To Maximize Your Profit',
		url: '/warehouse-optimization-tips/',
		tags: [
			BlogCategoryEnum['Logistics'],
			BlogCategoryEnum['Warehouse management']
		],
		date: new Date(2024, 9, 11),
		author: 'ivan',
		relatedUrlList: [
			'/what-is-warehouse-management-system/',
			'/our-works/lab-web-portal/',
			'/our-works/oil-and-gas-data-management-software/'
		]
	},
	{
		title: '7 Pillars of a Winning Telehealth Growth Strategy',
		url: '/the-pillars-of-a-winning-telehealth-strategy/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Telemedicine']
		],
		date: new Date(2024, 9, 12),
		author: 'oleg',
		relatedUrlList: [
			'/healthcare-website-design/',
			'/our-works/custom-telemedicine-solution/',
			'/our-works/std-status-app/'
		]
	},
	{
		title: 'How to Create a Remote Patient Monitoring Platform',
		url: '/remote-patient-monitoring-development-guide/',
		tags: [
			BlogCategoryEnum['Healthcare']
		],
		date: new Date(2024, 9, 18),
		author: 'ivan',
		relatedUrlList: [
			'/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
			'/ehr-system-development-process/',
			'/our-works/medical-information-system-software/'
		]
	},
	{
		title: 'How to Build a Personal Finance App: Steps, Cost, and Key Features',
		url: '/personal-finance-app-development-guide/',
		tags: [
			BlogCategoryEnum['Finance'],
			BlogCategoryEnum['Mobile']
		],
		date: new Date(2024, 7, 22),
		author: 'ivan',
		relatedUrlList: [
			'/how-to-build-a-mobile-banking-app/',
			'/personal-finance-app-development-guide/'
		]
	},
	{
		title: 'Essential Strategies for Developing Scalable Wellness Solutions',
		url: '/developing-wellness-tech-solutions/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Mobile'],
			BlogCategoryEnum['Wellness']
		],
		date: new Date(2024, 9, 30),
		author: 'oleg',
		relatedUrlList: [
			'/hipaa-compliant-telehealth-platform/',
			'/healthcare-mobile-app-trends/',
			'/remote-patient-monitoring-development-guide/'
		]
	},
	{
		title: 'Enhancing Customer Experience for AI Solutions',
		url: '/customer-experience-front-end-for-ai-solutions/',
		tags: [
			BlogCategoryEnum['Web'],
			BlogCategoryEnum['Customer experience'],
			BlogCategoryEnum['AI']
		],
		date: new Date(2024, 10, 14),
		author: 'ivan',
		relatedUrlList: [
			'/dynamic-route-planning-and-optimization-explained/',
			'/how-to-build-a-mobile-banking-app/',
			'/healthcare-website-design/'
		]
	},
	{
		title: 'Improving Rural Healthcare Access With Telehealth Technologies',
		url: '/telehealth-in-healthcare-rural-areas/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Telemedicine']
		],
		date: new Date(2024, 10, 22),
		author: 'ivan',
		relatedUrlList: [
			'/benefits-of-telehealth-for-patients-doctors/',
			'/the-pillars-of-a-winning-telehealth-strategy/',
			'/remote-patient-monitoring-development-guide/'
		]
	},
	{
		title: 'Medical Billing Automation for Specialized Healthcare Providers',
		url: '/medical-billing-automation-for-healthcare-providers/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Payments']
		],
		date: new Date(2024, 10, 23),
		author: 'oleg',
		relatedUrlList: [
			'/the-pillars-of-a-winning-telehealth-strategy/',
			'/how-to-create-laboratory-information-management-system/',
			'/pharmacy-app-development-process/'
		]
	},
	{
		title: 'Healthcare Logistics Software: Technologies That Save Lives',
		url: '/healthcare-logistics-overview/',
		tags: [
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Logistics'],
			BlogCategoryEnum['Supply chain management']
		],
		date: new Date(2024, 10, 1),
		author: 'oleg',
		relatedUrlList: [
			'/hipaa-compliant-telehealth-platform/',
			'/best-logistics-software/',
			'/warehouse-optimization-tips/'
		]
	},
	{
		title: 'How WMS Platforms Are Transforming Recruitment Processes',
		url: '/disrupting-recruitment-with-workforce-management-platforms/',
		tags: [
			BlogCategoryEnum['Business app'],
			BlogCategoryEnum['Workforce management']
		],
		date: new Date(2024, 11, 8),
		author: 'ivan',
		relatedUrlList: []
	},
	{
		title: 'Pharma Document Management to Meet Compliance Standards',
		url: '/pharmaceutical-document-management/',
		tags: [
			BlogCategoryEnum['Pharma'],
			BlogCategoryEnum['Healthcare'],
			BlogCategoryEnum['Business app']
		],
		date: new Date(2024, 11, 12),
		author: 'oleg',
		relatedUrlList: [
			'/what-is-a-lims/',
			'/how-to-create-laboratory-information-management-system/',
			'/ehr-for-patient-care/'
		]
	},
	{
		title: 'Proptech and Sustainability: Why Green Real Estate is the Future',
		url: '/future-of-proptech/',
		tags: [
			BlogCategoryEnum['PropTech']
		],
		date: new Date(2025, 1, 4),
		author: 'ivan',
		relatedUrlList: [
			'/how-to-build-property-listing-portal/',
			'/construction-industry-technology-trends/',
			'/real-estate-communication-with-advanced-tech/'
		]
	},
	{
		title: 'Effective Real Estate Communication in 2024: More Than Finding the Right Words',
		url: '/real-estate-communication-with-advanced-tech/',
		tags: [
			BlogCategoryEnum['PropTech']
		],
		date: new Date(2025, 1, 5),
		author: 'ivan',
		relatedUrlList: [
			'/how-to-build-property-listing-portal/',
			'/future-of-proptech/',
			'/our-works/saas-based-resident-experience-platform-for-senior-living/'
		]
	},
    {
        title: 'Building a Property Listing Portal: Key Steps for Success',
        url: '/how-to-build-property-listing-portal/',
        tags: [
            BlogCategoryEnum['Construction'],
            BlogCategoryEnum['Web'],
        ],
        date: new Date(2025, 2, 11),
        author: 'ivan',
        relatedUrlList: [
            '/detailed-guide-on-building-enterprise-app/',
            '/how-to-build-a-product-from-scratch/',
            '/basics-of-developing-on-demand-service-app/'
        ]
    },
    {
        title: 'On-hands Insights for Building a Medication Reminder & Tracker App',
        url: '/medication-reminder-app-development/',
        tags: [
            BlogCategoryEnum['Healthcare'],
            BlogCategoryEnum['Telemedicine']
        ],
        date: new Date(2025, 2, 12),
        author: 'oleg',
        relatedUrlList: [
            '/remote-patient-monitoring-development-guide/',
            '/our-works/saas-based-resident-experience-platform-for-senior-living/',
            '/developing-wellness-tech-solutions/'
        ]
    },
    {
        title: 'All-in-One Property Management Software for Landlords Development Guide',
        url: '/property-management-software-for-landlords/',
        tags: [
			BlogCategoryEnum['Construction']
        ],
        date: new Date(2025, 2, 26),
        author: 'ivan',
        relatedUrlList: [
            '/how-to-build-property-listing-portal/',
            '/real-estate-communication-with-advanced-tech/',
            '/future-of-proptech/'
        ]
    },
    {
        title: 'Primary & Chronic Care Solutions Transforming Healthcare',
        url: '/primary-chronic-care-management-solutions/',
        tags: [
            BlogCategoryEnum['Healthcare'],
            BlogCategoryEnum['Telemedicine'],
            BlogCategoryEnum['Mobile'],
        ],
        date: new Date(2025, 3, 5),
        author: 'oleg',
        relatedUrlList: [
            '/medication-reminder-app-development/',
            '/telehealth-in-healthcare-rural-areas/',
            '/remote-patient-monitoring-development-guide/'
        ]
    },
    {
        title: 'Optimizing Your IT Ecosystem Through Application Rationalization',
        url: '/application-rationalization-it-optimization-guide/',
        tags: [
            BlogCategoryEnum['App modernization'],
            BlogCategoryEnum['Platform development']
        ],
        date: new Date(2025, 3, 26),
        author: 'ivan',
        relatedUrlList: [
            '/our-works/esp-failure-analysis-solution-modernization/',
            '/our-works/national-lottery-app-modernization/',
            '/our-works/digital-platform-for-managing-gym-operation/'
        ]
    },
];

export const articles: Article[] = [
	{
		title: 'A SaaS-Based Resident Experience Platform for Senior Living',
		tags: [
			ArticleCategoryEnum['Healthcare'],
			ArticleCategoryEnum['Platform development'],
			ArticleCategoryEnum['Mobile'],
			ArticleCategoryEnum['AgeTech']
		],
		url: '/our-works/saas-based-resident-experience-platform-for-senior-living/',
		backgroundImage: 'saas-based-resident-experience-platform-for-senior-living-cover.webp',
		thumbnailImage: 'saas-based-resident-experience-platform-for-senior-living-thumb.webp',
		caseStudyTitle: 'A SaaS-Based Resident Experience Platform for Senior Living',
		relatedArticleUrls: [
			'/technology-for-elderly-care-services/',
			'/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
			'/healthcare-of-the-future/'
		],
		date: new Date(2023, 9, 6, 7, 3, 0, 0)
	},
	{
		title: 'Business Digitalization for a Chain of Canadian Medical Clinics',
		tags: [
			ArticleCategoryEnum['Healthcare'],
			ArticleCategoryEnum['Mobile'],
			ArticleCategoryEnum['Telemedicine']
		],
		url: '/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
		backgroundImage: 'business-digitalization-for-a-chain-of-canadian-medical-clinics.webp',
		thumbnailImage: 'business-digitalization-for-a-chain-of-canadian-medical-clinics-thumb.webp',
		caseStudyTitle: 'Business Digitalization for a Chain of Canadian Medical Clinics',
		relatedArticleUrls: [
			'/healthcare-of-the-future/',
			'/our-works/lab-web-portal/',
			'/our-works/medical-information-system-software/'
		],
		date: new Date(2023, 7, 28, 7, 3, 0, 0)
	},
	{
		title: 'AI-Driven Platform That Enables Value-Based Sales at Scale',
		tags: [
			ArticleCategoryEnum['Business app'],
			ArticleCategoryEnum['CRM']
		],
		url: '/our-works/sales-assistant-software/',
		backgroundImage: 'sales-assistant-software-cover.webp',
		thumbnailImage: 'sales-assistant-software-thumb.webp',
		caseStudyTitle: 'Sales Assistant Software',
		relatedArticleUrls: [
			'/our-works/construction-supply-chain-automation/',
			'/detailed-guide-on-building-enterprise-app/',
			'/our-works/sales-crm-for-education/'
		],
		date: new Date(2023, 12, 6, 10, 16, 0, 0)
	},
	{
		title: 'STD Status App Development',
		tags: [
			ArticleCategoryEnum['Healthcare'],
			ArticleCategoryEnum['Business app'],
			ArticleCategoryEnum['Mobile']
		],
		url: '/our-works/std-status-app/',
		backgroundImage: 'std-cover.webp',
		thumbnailImage: 'std-thumb.webp',
		caseStudyTitle: 'STD Status App Development',
		relatedArticleUrls: [
			'/our-works/custom-telemedicine-solution/',
			'/our-works/medical-information-system-software/',
			'/our-works/health-metrics/'
		],
		date: new Date(2023, 3, 1, 7, 3, 0, 0)
	},
	{
		title: 'Sloper Climbing App',
		tags: [
			ArticleCategoryEnum['Mobile'],
			ArticleCategoryEnum['Travel & Hospitality']
		],
		url: '/our-works/sloper-climbing-app/',
		backgroundImage: 'slopper.webp',
		thumbnailImage: 'slopper-thumb.webp',
		caseStudyTitle: 'Sloper Climbing App',
		relatedArticleUrls: [
			'/mobile-development/',
			'/our-works/road-trip-mobile-app/',
			'/our-works/travel-management-mobile-app/'
		],
		date: new Date(2021, 5, 12, 7, 3, 0, 0)
	},
	{
		title: 'Construction Supply Chain Automation',
		tags: [
			ArticleCategoryEnum['Construction'],
			ArticleCategoryEnum['Business app'],
			ArticleCategoryEnum['Supply chain management']
		],
		url: '/our-works/construction-supply-chain-automation/',
		backgroundImage: 'construction-supply-chain-automation-cover.webp',
		thumbnailImage: 'construction-supply-chain-automation-thumb.webp',
		caseStudyTitle: 'Logiplan',
		relatedArticleUrls: [
			'/our-works/quarry-automation-platform/',
			'/our-works/emergency-notifier/',
			'/erp-for-construction-industry-and-infrastructure/'
		],
		date: new Date(2023, 11, 7, 13, 55, 0, 0)
	},
	{
		title: 'Migration to Multi-Tenant SaaS Platform',
		tags: [
			ArticleCategoryEnum['Finance'],
			ArticleCategoryEnum['Business App'],
			ArticleCategoryEnum['App modernization'],
			ArticleCategoryEnum['CRM'],
		],
		url: '/our-works/migration-to-multi-tenant-saas-platform/',
		backgroundImage: 'migration-to-multi-tenant-saas-platform-cover.webp',
		thumbnailImage: 'migration-to-multi-tenant-saas-platform-thumb.webp',
		caseStudyTitle: 'Migration to Multi-Tenant SaaS Platform',
		relatedArticleUrls: [
			'/our-works/land-tax-software/',
			'/detailed-guide-on-building-enterprise-app/',
			'/our-works/sales-assistant-software/'
		],
		date: new Date(2023, 11, 23, 10, 11, 1, 0)
	},
	{
		title: 'Custom Telemedicine Solution',
		tags: [
			ArticleCategoryEnum['Healthcare'],
			ArticleCategoryEnum['Telemedicine']
		],
		url: '/our-works/custom-telemedicine-solution/',
		backgroundImage: 'custom-telemedicine-solution.webp',
		thumbnailImage: 'custom-telemedicine-solution-thumb.webp',
		caseStudyTitle: 'Custom Telemedicine Solution',
		relatedArticleUrls: [
			'/the-pillars-of-a-winning-telehealth-strategy/',
			'/our-works/formula-compounder/',
			'/our-works/health-metrics/'
		],
		date: new Date(2022, 4, 19, 20, 34, 0, 0)
	},
	{
		title: 'Formula Compounder',
		tags: [
			ArticleCategoryEnum['Healthcare'],
			ArticleCategoryEnum['Business app'],
			ArticleCategoryEnum['LIMS']
		],
		url: '/our-works/formula-compounder/',
		backgroundImage: 'formula-compounder.webp',
		thumbnailImage: 'formula-compounder-thumb.webp',
		caseStudyTitle: 'Formula Compounder',
		relatedArticleUrls: [
			'/our-works/lab-web-portal/',
			'/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
			'/our-works/health-metrics/'
		],
		date: new Date(2020, 12, 8, 11, 10, 0, 0)
	},
	{
		title: 'Lab Web Portal',
		tags: [
			ArticleCategoryEnum['Healthcare'],
			ArticleCategoryEnum['Business app'],
			ArticleCategoryEnum['LIMS']
		],
		url: '/our-works/lab-web-portal/',
		backgroundImage: 'lab-web-portal.webp',
		thumbnailImage: 'lab-web-portal-thumb.webp',
		caseStudyTitle: 'Lab Web Portal',
		relatedArticleUrls: [
			'/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
			'/our-works/health-metrics/',
			'/our-works/formula-compounder/'
		],
		date: new Date(2020, 11, 25, 7, 47, 0, 0)
	},
	{
		title: 'Optimize IT with SCOM Health Check V2',
		tags: [
			ArticleCategoryEnum['Healthcare'],
			ArticleCategoryEnum['Business app'],
			ArticleCategoryEnum['HIS']
		],
		url: '/our-works/scom-health-check-v2/',
		backgroundImage: 'scom-health-check-v2.webp',
		thumbnailImage: 'scom-health-check-v2-thumb.webp',
		caseStudyTitle: 'Optimize IT with SCOM Health Check V2',
		relatedArticleUrls: [
			'/our-works/lab-web-portal/',
			'/our-works/formula-compounder/',
			'/our-works/health-metrics/'
		],
		date: new Date(2020, 11, 25, 13, 57, 0, 0)
	},
	{
		title: 'Medical Information System',
		tags: [
			ArticleCategoryEnum['Healthcare'],
			ArticleCategoryEnum['Business app'],
			ArticleCategoryEnum['EHR/EMR']
		],
		url: '/our-works/medical-information-system-software/',
		backgroundImage: 'medical-information-system-software.webp',
		thumbnailImage: 'medical-information-system-software-thumb.webp',
		caseStudyTitle: 'Medical Information System',
		relatedArticleUrls: [
			'/our-works/lab-web-portal/',
			'/our-works/health-metrics/',
			'/our-works/formula-compounder/'
		],
		date: new Date(2020, 11, 25, 13, 6, 0, 0)
	},
	{
		title: 'Patient Feedback and Engagement Software Development',
		tags: [
			ArticleCategoryEnum['Healthcare'],
			ArticleCategoryEnum['Business app'],
			ArticleCategoryEnum['HIS']
		],
		url: '/our-works/health-metrics/',
		backgroundImage: 'health-metrics.webp',
		thumbnailImage: 'health-metrics-thumb.webp',
		caseStudyTitle: 'Patient Feedback and Engagement Software Development',
		relatedArticleUrls: [
			'/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
			'/our-works/digital-solution-for-veteran-rehabilitation-center/',
			'/our-works/lab-web-portal/'
		],
		date: new Date(2024, 11, 11, 12, 25, 41, 0)
	},
	{
		title: 'Quarry Automation Platform',
		tags: [
			ArticleCategoryEnum['Energy & Utilities'],
			ArticleCategoryEnum['Platform development'],
			ArticleCategoryEnum['Mining']
		],
		url: '/our-works/quarry-automation-platform/',
		backgroundImage: 'quarry-automation-platform.webp',
		thumbnailImage: 'quarry-automation-platform-thumb.webp',
		caseStudyTitle: 'Quarry Automation Platform',
		relatedArticleUrls: [
			'/our-works/emergency-notifier/',
			'/our-works/construction-supply-chain-automation/',
			'/supply-chain-management-software-features-requirements/'
		],
		date: new Date(2021, 5, 12, 7, 18, 0, 0)
	},
	{
		title: 'Logistics Web and Mobile Software',
		tags: [
			ArticleCategoryEnum['Logistics'],
			ArticleCategoryEnum['Mobile'],
			ArticleCategoryEnum['Web'],
			ArticleCategoryEnum['Fleet Management']
		],
		url: '/our-works/optimizing-trucking-logistics/',
		backgroundImage: 'optimizing-trucking-logistics.webp',
		thumbnailImage: 'optimizing-trucking-logistics-thumb.webp',
		caseStudyTitle: 'Logistics Web and Mobile Software',
		relatedArticleUrls: [
			'/what-is-warehouse-management-system/',
			'/dynamic-route-planning-and-optimization-explained/',
			'/trucking-app-development-guide/'
		],
		date: new Date(2021, 5, 6, 9, 11, 0, 0)
	},
	{
		title: 'MYS Mobile GPS Tracker',
		tags: [
			ArticleCategoryEnum['Business app']
		],
		url: '/our-works/mind-your-service-mobile-gps-tracker/',
		backgroundImage: 'mind-your-service-mobile-gps-tracker.webp',
		thumbnailImage: 'mind-your-service-mobile-gps-tracker-thumb.webp',
		caseStudyTitle: 'MYS Mobile GPS Tracker',
		relatedArticleUrls: [
			'/our-works/ev-charging-software/',
			'/trucking-app-development-guide/',
			'/dynamic-route-planning-and-optimization-explained/'
		],
		date: new Date(2020, 11, 24, 15, 53, 0, 0)
	},
	{
		title: 'Electric Vehicle Charging Software',
		tags: [
			ArticleCategoryEnum['GreenTech'],
			ArticleCategoryEnum['Mobility']
		],
		url: '/our-works/ev-charging-software/',
		backgroundImage: 'ev-charging-software.webp',
		thumbnailImage: 'ev-charging-software-thumb.webp',
		caseStudyTitle: 'Electric Vehicle Charging Software',
		relatedArticleUrls: [
			'/our-works/mind-your-service-mobile-gps-tracker/',
			'/our-works/freelance-marketplace-development/',
			'/dynamic-route-planning-and-optimization-explained/'
		],
		date: new Date(2020, 11, 23, 11, 7, 0, 0)
	},
	{
		title: 'Road Trip Mobile App',
		tags: [
			ArticleCategoryEnum['Mobile'],
			ArticleCategoryEnum['Travel & Hospitality']
		],
		url: '/our-works/road-trip-mobile-app/',
		backgroundImage: 'road-trip-mobile-app.webp',
		thumbnailImage: 'road-trip-mobile-app-thumb.webp',
		caseStudyTitle: 'Road Trip Mobile App',
		relatedArticleUrls: [
			'/our-works/sloper-climbing-app/',
			'/our-works/travel-management-mobile-app/',
			'/our-works/ev-charging-software/'
		],
		date: new Date(2021, 9, 16, 10, 4, 0, 0)
	},
	{
		title: 'Outdoor Ads Management Mobile App',
		tags: [
			ArticleCategoryEnum['Mobile'],
			ArticleCategoryEnum['Media & Entertainment']
		],
		url: '/our-works/outdoor-advertising-management-software/',
		backgroundImage: 'outdoor-advertising-management-software.webp',
		thumbnailImage: 'outdoor-advertising-management-software-thumb.webp',
		caseStudyTitle: 'Outdoor Ads Management Mobile App',
		relatedArticleUrls: [
			'/how-to-build-a-product-from-scratch/',
			'/our-works/road-trip-mobile-app/',
			'/our-works/ev-charging-software/'
		],
		date: new Date(2021, 9, 1, 15, 32, 0, 0)
	},
	{
		title: 'Travel Management Mobile App',
		tags: [
			ArticleCategoryEnum['Mobile'],
			ArticleCategoryEnum['Travel & Hospitality']
		],
		url: '/our-works/travel-management-mobile-app/',
		backgroundImage: 'travel-management-mobile-app.webp',
		thumbnailImage: 'travel-management-mobile-app-thumb.webp',
		caseStudyTitle: 'Travel Management Mobile App',
		relatedArticleUrls: [
			'/mobile-development/',
			'/our-works/sloper-climbing-app/',
			'/our-works/road-trip-mobile-app/'
		],
		date: new Date(2021, 5, 12, 7, 6, 0, 0)
	},
	{
		title: 'Agriculture Software Solutions',
		tags: [
			ArticleCategoryEnum['Agriculture']
		],
		url: '/our-works/agriculture-software-solutions/',
		backgroundImage: 'agriculture-software-solutions.webp',
		thumbnailImage: 'agriculture-software-solutions-thumb.webp',
		caseStudyTitle: 'Agriculture Software Solutions',
		relatedArticleUrls: [
			'/what-is-warehouse-management-system/',
			'/dynamic-route-planning-and-optimization-explained/',
			'/trucking-app-development-guide/'
		],
		date: new Date(2021, 5, 11, 6, 14, 0, 0)
	},
	{
		title: 'Food safety management software',
		tags: [
			ArticleCategoryEnum['Business app']
		],
		url: '/our-works/audit-management-system/',
		backgroundImage: 'audit-management-system.webp',
		thumbnailImage: 'audit-management-system-thumb.webp',
		caseStudyTitle: 'Food safety management software',
		relatedArticleUrls: [
			'/our-works/lab-web-portal/',
			'/our-works/emergency-notifier/',
			'/our-works/agriculture-software-solutions/'
		],
		date: new Date(2020, 11, 25, 15, 13, 0, 0)
	},
	{
		title: 'Emergency Notifier',
		tags: [
			ArticleCategoryEnum['Business app'],
			ArticleCategoryEnum['Construction'],
			ArticleCategoryEnum['Mining']
		],
		url: '/our-works/emergency-notifier/',
		backgroundImage: 'emergency-notifier.webp',
		thumbnailImage: 'emergency-notifier-thumb.webp',
		caseStudyTitle: 'Emergency Notifier',
		relatedArticleUrls: [
			'/our-works/mind-your-service/',
			'/our-works/construction-supply-chain-automation/',
			'/our-works/quarry-automation-platform/'
		],
		date: new Date(2020, 11, 25, 10, 33, 0, 0)
	},
	{
		title: 'Bridgestone Survey Mobile App',
		tags: [
			ArticleCategoryEnum['Business app']
		],
		url: '/our-works/bridgestone-car-park-survey/',
		backgroundImage: 'bridgestone-car-park-survey.webp',
		thumbnailImage: 'bridgestone-car-park-survey-thumb.webp',
		caseStudyTitle: 'Bridgestone Survey Mobile App',
		relatedArticleUrls: [
			'/our-works/network-deposition-services/',
			'/our-works/time-booking-tool/',
			'/our-works/hostoo/'
		],
		date: new Date(2020, 11, 24, 16, 34, 0, 0)
	},
	{
		title: 'Freelance Marketplace Development',
		tags: [
			ArticleCategoryEnum['Education'],
			ArticleCategoryEnum['Platform development']
		],
		url: '/our-works/freelance-marketplace-development/',
		backgroundImage: 'freelance-marketplace-development.webp',
		thumbnailImage: 'freelance-marketplace-development-thumb.webp',
		caseStudyTitle: 'Freelance Marketplace Development',
		relatedArticleUrls: [
			'/how-to-build-a-product-from-scratch/',
			'/our-works/sales-crm-for-education/',
			'/our-works/fee-foundation-of-economic-education/'
		],
		date: new Date(2022, 11, 30, 10, 18, 0, 0)
	},
	{
		title: 'Land Tax Management Software Development',
		tags: [
			ArticleCategoryEnum['Finance']
		],
		url: '/our-works/land-tax-software/',
		backgroundImage: 'land-tax-software.webp',
		thumbnailImage: 'land-tax-software-thumb.webp',
		caseStudyTitle: 'Land Tax Management Software Development',
		relatedArticleUrls: [
			'/our-works/migration-to-multi-tenant-saas-platform/',
			'/detailed-guide-on-building-enterprise-app/',
			'/our-works/construction-supply-chain-automation/'
		],
		date: new Date(2022, 4, 20, 9, 56, 0, 0)
	},
	{
		title: 'Efficient Sales CRM Development for Education',
		tags: [
			ArticleCategoryEnum['Education'],
			ArticleCategoryEnum['Business app'],
			ArticleCategoryEnum['CRM']
		],
		url: '/our-works/sales-crm-for-education/',
		backgroundImage: 'sales-crm-for-education.webp',
		thumbnailImage: 'sales-crm-for-education-thumb.webp',
		caseStudyTitle: 'Efficient Sales CRM Development for Education',
		relatedArticleUrls: [
			'/our-works/migration-to-multi-tenant-saas-platform/',
			'/our-works/freelance-marketplace-development/',
			'/detailed-guide-on-building-enterprise-app/'
		],
		date: new Date(2022, 4, 19, 20, 19, 0, 0)
	},
	{
		title: 'Scan Tool',
		tags: [
			ArticleCategoryEnum['Business app']
		],
		url: '/our-works/scan-tool/',
		backgroundImage: 'scan-tool.webp',
		thumbnailImage: 'scan-tool-thumb.webp',
		caseStudyTitle: 'Scan Tool',
		relatedArticleUrls: [
			'/our-works/lab-web-portal/',
			'/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
			'/our-works/health-metrics/'
		],
		date: new Date(2020, 12, 8, 10, 44, 0, 0)
	},
	{
		title: 'MindYourService',
		tags: [
			ArticleCategoryEnum['Business app'],
			ArticleCategoryEnum['Logistics'],
			ArticleCategoryEnum['Asset Monitoring']
		],
		url: '/our-works/mind-your-service/',
		backgroundImage: 'mind-your-service.webp',
		thumbnailImage: 'mind-your-service-thumb.webp',
		caseStudyTitle: 'MindYourService',
		relatedArticleUrls: [
			'/our-works/network-deposition-services/',
			'/detailed-guide-on-building-enterprise-app/',
			'/our-works/scan-tool/'
		],
		date: new Date(2020, 11, 25, 19, 59, 0, 0)
	},
	{
		title: 'Efficient Oil & Gas Data Management Platform Development',
		tags: [
			ArticleCategoryEnum['Energy & Utilities'],
			ArticleCategoryEnum['Mining']
		],
		url: '/our-works/oil-and-gas-data-management-software/',
		backgroundImage: 'oil-and-gas-data-management-software.webp',
		thumbnailImage: 'oil-and-gas-data-management-software-thumb.webp',
		caseStudyTitle: 'Efficient Oil & Gas Data Management Platform Development',
		relatedArticleUrls: [
			'/our-works/quarry-automation-platform/',
			'/our-works/emergency-notifier/',
			'/detailed-guide-on-building-enterprise-app/'
		],
		date: new Date(2023, 2, 15, 10, 30, 5, 0)
	},
	{
		title: 'Time Booking Tool',
		tags: [
			ArticleCategoryEnum['Business app']
		],
		url: '/our-works/time-booking-tool/',
		backgroundImage: 'time-booking-tool.webp',
		thumbnailImage: 'time-booking-tool-thumb.webp',
		caseStudyTitle: 'Time Booking Tool',
		relatedArticleUrls: [
			'/our-works/network-deposition-services/',
			'/our-works/hostoo/',
			'/our-works/audit-management-system/'
		],
		date: new Date(2020, 11, 25, 14, 13, 0, 0)
	},
	{
		title: 'Litigation Management Application Development',
		tags: [
			ArticleCategoryEnum['Business app']
		],
		url: '/our-works/network-deposition-services/',
		backgroundImage: 'network-deposition-services.webp',
		thumbnailImage: 'network-deposition-services-thumb.webp',
		caseStudyTitle: 'Litigation Management Application Development',
		relatedArticleUrls: [
			'/our-works/time-booking-tool/',
			'/our-works/hostoo/',
			'/our-works/scan-tool/'
		],
		date: new Date(2022, 8, 26, 7, 44, 0, 0)
	},
	{
		title: 'Hostoo',
		tags: [
			ArticleCategoryEnum['Business app']
		],
		url: '/our-works/hostoo/',
		backgroundImage: 'hostoo.webp',
		thumbnailImage: 'hostoo-thumb.webp',
		caseStudyTitle: 'Hostoo',
		relatedArticleUrls: [
			'/our-works/bridgestone-car-park-survey/',
			'/our-works/audit-management-system/',
			'/our-works/scan-tool/'
		],
		date: new Date(2020, 11, 25, 13, 18, 0, 0)
	},
	{
		title: 'Foundation for EE',
		tags: [
			ArticleCategoryEnum['Education'],
			ArticleCategoryEnum['Web']
		],
		url: '/our-works/fee-foundation-of-economic-education/',
		backgroundImage: 'fee-foundation-of-economic-education.webp',
		thumbnailImage: 'fee-foundation-of-economic-education-thumb.webp',
		caseStudyTitle: 'Foundation for EE',
		relatedArticleUrls: [
			'/our-works/freelance-marketplace-development/',
			'/our-works/sales-crm-for-education/',
			'/our-works/scan-tool/'
		],
		date: new Date(2020, 11, 25, 11, 26, 0, 0)
	},
	{
		title: 'Improving Quality Assurance in Tech Projects of a Top US-Based Bank',
		tags: [
			ArticleCategoryEnum['Outsourcing'],
			ArticleCategoryEnum['QA'],
			ArticleCategoryEnum['Finance']
		],
		url: '/our-works/qa-services-for-bank/',
		backgroundImage: 'qa-services-for-bank-bg.webp',
		thumbnailImage: 'qa-services-for-bank-thumb.webp',
		caseStudyTitle: 'Improving Quality Assurance in Tech Projects of a Top US-Based Bank',
		relatedArticleUrls: [
			'/our-works/migration-to-multi-tenant-saas-platform/',
			'/our-works/land-tax-software/',
			'/how-to-build-a-mobile-banking-app/'
		],
		date: new Date(2024, 1, 3, 8, 45, 0, 0)
	},
	{
		title: 'A Data Management, Reporting & Analytics Solution for Tax Offices in the US',
		tags: [
			ArticleCategoryEnum['Finance'],
			ArticleCategoryEnum['Business app'],
			ArticleCategoryEnum['Data']
		],
		url: '/our-works/data-management-tool-for-tax-offices/',
		backgroundImage: 'data-management-tool-for-tax-offices-bg.webp',
		thumbnailImage: 'data-management-tool-for-tax-offices-thumb.webp',
		caseStudyTitle: 'A Data Management, Reporting & Analytics Solution for Tax Offices in the US',
		relatedArticleUrls: [
			'/our-works/hostoo/',
			'/our-works/migration-to-multi-tenant-saas-platform/',
			'/our-works/network-deposition-services/'
		],
		date: new Date(2024, 4, 1, 8, 21, 15, 0)
	},
	{
		title: 'A Digital Solution for Veteran Rehabilitation Center',
		tags: [
			ArticleCategoryEnum['Healthcare'],
			ArticleCategoryEnum['Business app'],
			ArticleCategoryEnum['Wellness'],
			ArticleCategoryEnum['Mental Health']
		],
		url: '/our-works/digital-solution-for-veteran-rehabilitation-center/',
		backgroundImage: 'digital-solution-for-veteran-rehabilitation-center-bg.webp',
		thumbnailImage: 'digital-solution-for-veteran-rehabilitation-center-thumb.webp',
		caseStudyTitle: 'A Digital Solution for Veteran Rehabilitation Center',
		relatedArticleUrls: [
			'/our-works/saas-based-resident-experience-platform-for-senior-living/',
			'/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
			'/our-works/health-metrics/'
		],
		date: new Date(2024, 8, 8, 15, 15, 11, 0)
	},
	{
		title: 'Modernizing the Technology Stack for a Fitness Chain’s Software Platform',
		tags: [
			ArticleCategoryEnum['Healthcare'],
			ArticleCategoryEnum['Business app'],
			ArticleCategoryEnum['Wellness'],
			ArticleCategoryEnum['Fitness']
		],
		url: '/our-works/digital-platform-for-managing-gym-operation/',
		backgroundImage: 'digital-platform-for-managing-gym-operation-bg.webp',
		thumbnailImage: 'digital-platform-for-managing-gym-operation-thumb.webp',
		caseStudyTitle: 'Modernizing the Technology Stack for a Fitness Chain’s Software Platform',
		relatedArticleUrls: [
			'/our-works/migration-to-multi-tenant-saas-platform/',
			'/our-works/business-digitalization-for-a-chain-of-canadian-medical-clinics/',
			'/our-works/sales-assistant-software/'
		],
		date: new Date(2024, 10, 10, 8, 49, 1, 0)
	},
	{
		title: 'Legacy Application Modernization for a National Lottery',
		tags: [
			ArticleCategoryEnum['Mobile']
		],
		url: '/our-works/national-lottery-app-modernization/',
		backgroundImage: 'national-lottery-app-modernization-bg.webp',
		thumbnailImage: 'national-lottery-app-modernization-thumb.webp',
		caseStudyTitle: 'Legacy Application Modernization for a National Lottery',
		relatedArticleUrls: [
			'/our-works/digital-platform-for-managing-gym-operation/',
			'/our-works/optimizing-trucking-logistics/',
			'/our-works/migration-to-multi-tenant-saas-platform/'
		],
		date: new Date(2024, 11, 14, 10, 28, 4, 0)
	},
	{
		title: 'Assistive Listening Systems to Increase Venue Accessibility for Hearing Aid Users',
		tags: [
			ArticleCategoryEnum['Media & Entertainment'],
			ArticleCategoryEnum['Business app'],
			ArticleCategoryEnum['Web'],
		],
		url: '/our-works/web-portal-for-assistive-listening-systems/',
		backgroundImage: 'web-portal-for-assistive-listening-systems-bg.webp',
		thumbnailImage: 'web-portal-for-assistive-listening-systems-thumb.webp',
		caseStudyTitle: 'Assistive Listening Systems to Increase Venue Accessibility for Hearing Aid Users',
		relatedArticleUrls: [
			'/our-works/formula-compounder/',
			'/our-works/digital-solution-for-veteran-rehabilitation-center/',
			'/our-works/saas-based-resident-experience-platform-for-senior-living/'
		],
		date: new Date(2025, 2, 13, 10, 27, 41, 0)
	},
	{
		title: 'Web Portal for Analyzing Incidents on Electrical Submersible Pumps (ESP)',
		tags: [
			ArticleCategoryEnum['Energy & Utilities']
		],
		url: '/our-works/esp-failure-analysis-solution-modernization/',
		backgroundImage: 'esp-failure-analysis-solution-modernization-bg.webp',
		thumbnailImage: 'esp-failure-analysis-solution-modernization-thumb.webp',
		caseStudyTitle: 'Web Portal for Analyzing Incidents on Electrical Submersible Pumps (ESP)',
		relatedArticleUrls: [
			'/our-works/oil-and-gas-data-management-software/',
			'/our-works/quarry-automation-platform/',
			'/our-works/digital-platform-for-managing-gym-operation/',
		],
		date: new Date(2025, 2, 20, 15, 14, 4, 0)
	}
];

export const reviews: Review[] = [
	{
		text: 'We developed these software solutions for a chain of medical clinics in Canada and Mexico. Seeking to embrace digitalization and introduce telemedicine, the client asked us to build an app with features like personal profiles, access to medical records, and scheduling. Additionally, we had to develop a web portal to automate basic tasks for doctors and medical staff. Our team successfully implemented this project from scratch, enabling the client to expand their reach with remote services',
		jobTitle: '',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['business-digitalization-for-a-chain-of-canadian-medical-clinics']
	},
	{
		text: 'Exoft is well-adjusted to the client’s timeline, development cycle, and requirements. The team fosters transparency and open communication, which have been pivotal in managing the client’s expectations and recalibrating the quality of outsourced developers.It was crucial for developing our modern sales assistant software.',
		jobTitle: 'The Company’s Executive',
		source: 'clutch',
		starsCount: 5,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['sales-assistant-software']
	},
	{
		text: 'The main reason I enjoy working with Exoft is that they produce consistently high-quality work. We looked at several outsourced solutions for our development activities and found that Exoft provides skilled resources at very good hourly rates. The developers continuously strive to understand our requirements and build robust solutions.',
		jobTitle: 'The client’s Product Owner',
		source: 'clutch',
		starsCount: 5,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['std-status-app']
	},
	{
		text: 'Exoft offers a talented and dedicated team that has successfully helped expedite the development process. They have proven to be reliable and accommodating, easily adjusting their workflow to match project requirements...',
		jobTitle: 'Slava Ceornea, CTO',
		source: 'clutch',
		starsCount: 5,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['sloper-climbing-app']
	},
	{
		text: 'We have built an all-in-one platform that gives control over every aspect of the construction supply chain. This solution, which caters to over 200 construction professionals, includes two components: one that addresses supply chain needs on a construction site and the other one intended for suppliers, distributors, and logistics terminal personnel. We worked on this project from ideation to post-release, rolling out the necessary updates.',
		jobTitle: '',
		source: 'clutch',
		starsCount: 5,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['construction-supply-chain-automation']
	},
	{
		text: 'We created a cutting-edge telemedicine platform supporting video calls, chats, and payments. Available to patients from around the globe, it gives doctors an extra source of income alongside their primary job. The solution helped the client become a leader in the rapidly growing telehealth market.',
		jobTitle: '',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['custom-telemedicine-solution']
	},
	{
		text: 'Our team built this robust laboratory information management system (LIMS) for a European pharmaceutical company struggling to streamline its drug manufacturing process. The LIMS offers editable templates for simplified formula compounding, formula usage tracking, a component database, and more. Prior to building this solution, we did thorough market research and mapped the user journey. The client highly appreciated our work and provided positive feedback',
		jobTitle: '',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['formula-compounder']
	},
	{
		text: 'Great understanding. We were on the same page from the very beginning',
		jobTitle: 'CMO at medical center',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['lab-web-portal']
	},
	{
		text: 'They’re a wonderful company, and we thoroughly enjoyed working with them',
		jobTitle: "The client's CMO",
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['scom-health-check-v2']
	},
	{
		text: 'We developed this solution for a client whose aim was to modernize healthcare organizations by transforming clinician-patient interactions and streamlining hospital workflows. Building this medical information system from the ground up, our team implemented video conferencing, in-app messaging, notifications, and other features. Ensuring robust data protection was our top priority.',
		jobTitle: "VP of Innovation & Development at the Client's Organization",
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['medical-information-system-software']
	},
	{
		text: 'On the design side of things and in general, their coding ability is very high',
		jobTitle: "The client's CEO",
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['health-metrics']
	},
	{
		text: 'Exoft has handled all the tasks I’ve given them very well. They were willing to work longer hours when we needed something urgently. They’re always thinking about how our projects can be improved, and they always perform research before executing a task.',
		jobTitle: "The client's Product Owner",
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['quarry-automation-platform']
	},
	{
		text: 'We created this solution for a top logistics company with a vast carrier network, a fleet of over 60,000 vehicles, and handling over 100,000 deliveries monthly. Eager to enhance customer service, they commissioned us to develop a web system and a mobile app to streamline operations, automate tasks, enable real-time tracking, and more. We took up the project at the idea stage and supported it all the way to its launch, ensuring advanced security and usability.',
		jobTitle: '',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['optimizing-trucking-logistics']
	},
	{
		text: "Our team built this mobile app for a UK-based software vendor. They aimed to develop a solution allowing companies to track their field employees' locations using GPS technology. The app offers real-time location monitoring, task assignment, movement history tracking, and other features. We joined the project early on after the previous team struggled to meet expectations and supported it until the app’s successful release.",
		jobTitle: '',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['mind-your-service-mobile-gps-tracker']
	},
	{
		text: 'Company provided services as requested! We did not have to use resources to recruit or administer for these roles',
		jobTitle: "Head of Engineering at the client's company",
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['ev-charging-software']
	},
	{
		text: 'We were pleased with the results of our product. Exoft provided quick and efficient workflow, and their precise estimates made us consider them a trusted partner with great experience. They were always responsive, accessible, and friendly.',
		jobTitle: "The client's Product Owner",
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['road-trip-mobile-app']
	},
	{
		text: 'Functionalities produced by Exoft have significantly improved website performance. Exoft displayed quality communication and responded to queries fast.',
		jobTitle: 'Startup Founder & CEO',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['outdoor-advertising-management-software']
	},
	{
		text: 'We definitely had a positive experience working with Exoft. When we reviewed their work, the app had very few bugs, and we didn’t request very many changes to their work. They followed our design very closely.',
		jobTitle: 'Startup Founder & CEO',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['travel-management-mobile-app']
	},
	{
		text: 'They successfully developed solution has garnered considerable industry praise. Exoft have received very positive feedback from medical practitioners',
		jobTitle: '',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['agriculture-software-solutions']
	},
	{
		text: 'They are easy to work with',
		jobTitle: "CEO at the client's company",
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['audit-management-system']
	},
	{
		text: "An emergency notifier mobile app we created for a construction company serves as a go-to tool for sending urgent messages across communication channels. The message recipients are office and remote employees who use various devices, such as computers, smartphones, and tablets. Our team built the app from the ground up and seamlessly integrated it with the client's corporate systems, including legacy ones, ensuring smooth functionality across the board. ",
		jobTitle: '',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['emergency-notifier']
	},
	{
		text: 'We consider Exoft as a partner, not just a vendor.',
		jobTitle: 'Head of BTL at Bridgestone',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['bridgestone-car-park-survey']
	},
	{
		text: 'The amount of knowledge and expertise Exoft has in freelance marketplace development is impressive. Their team always engaged with us to help refine online platform features to make them the best they can be, going a level deeper than just developing what we request. They were great at meeting all UI/UX and functional requirements for our freelance marketplace, which was very helpful. Exoft is a great, trustworthy, and talented team that goes above and beyond to help deliver freelance market products of great value. ',
		jobTitle: 'CTO at Eloquenti',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['freelance-marketplace-development']
	},
	{
		text: 'We developed this comprehensive tax management solution for a US-based consulting company. The platform assists tax professionals in calculating land tax, filing tax returns, and ensuring overall tax compliance. The key challenge was to ensure the fast retrieval of information from a large database, which we addressed using Apache Solr — a Java platform enabling full-text search and indexing. Our guidance throughout the three-year project spanned from ideation to post-release product updates.',
		jobTitle: '',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['land-tax-software']
	},
	{
		text: 'So far, it’s been a successful partnership. The Exoft team has been passing deliverables on time and with utmost quality. They’ve mostly impressed us with their quick problem-solving skills, which continue to ensure a delay-free engagement. They are also honest, flexible, and communicative.',
		jobTitle: "The company's Director",
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['sales-crm-for-education']
	},
	{
		text: 'I’ve expressed great satisfaction with the completed by Exoft',
		jobTitle: '',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['scan-tool']
	},
	{
		text: 'We were impressed by how they met our expectations and how quickly they delivered',
		jobTitle: 'CTO at MYS',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['mind-your-service']
	},
	{
		text: 'Throughout the relationship, Exoft has been an extremely supportive partner. In addition to communicating in a timely manner, the team is proactive and reactive, quickly addressing any issues that arise. Their ability to work with out-of-the-box ideas sets them apart from other vendors',
		jobTitle: 'Sami Suheil, COO at WellsUp',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['oil-and-gas-data-management-software']
	},
	{
		text: 'I would definitely recommend them. Attention to detail and constant feedback is impressive.',
		jobTitle: "COO at client's company",
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['time-booking-tool']
	},
	{
		text: 'Exoft is open-minded. We talk openly about things, and we don’t hide anything. It’s perfect. They are perfect. Take them as they are. They are cool and produce great quality work',
		jobTitle: "Client's CEO",
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['network-deposition-services']
	},
	{
		text: 'Excellent. Both our team and Exoft’s acted as a unit',
		jobTitle: 'Managing Director',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['hostoo']
	},
	{
		text: 'The team did even more than they were asked to do',
		jobTitle: 'COO at FEE',
		source: undefined,
		starsCount: 0,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['fee-foundation-of-economic-education']
	},
	{
		text: 'We smoothly transitioned the client’s CRM platform from a desktop version to a multi-tenant SaaS solution without disrupting business operations. Post-migration, we built a series of new features to meet evolving user needs. As a result, the client received a cutting-edge CRM system that empowers customer-centric service. The system actively measures customer engagement, unifies communication across channels, provides data-driven insights into customer experience, and enhances account management.',
		jobTitle: '',
		source: undefined,
		starsCount: 5,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['migration-to-multi-tenant-saas-platform']
	},
	{
		text: 'We turned the client’s idea into a full-fledged SaaS solution that improves senior living with meaningful social interactions. The platform connects nursing home residents, their families, caregivers, and service providers. This achievement earned the client the title of the International AgeTech StartUp of the Year 2022.',
		jobTitle: '',
		source: undefined,
		starsCount: 5,
		url: 'https://clutch.co/profile/exoft',
		relatedArticleUrls: ['saas-based-resident-experience-platform-for-senior-living']
	}
];

export const feed: Feedback[] = [
	{
		icon: 'boris',
		text: 'Exoft has successfully supported us to push our business forward. They bounce ideas off each other and give feedback and context to help in tech areas.<br><br>Moreover, they’re approachable, responsive partners, and the team stands out for their expertise. It feels more like an internal team than an outsourcing relationship. thank you.',
		name: 'Boris Jinjolava',
		jobTitle: 'CEO at ViCLinic Inc.',
		productName: 'Healthcare Software Development',
		productDescription:
			'We provide healthcare software development services. Cut costs, optimize workflows, and improve patient care with a solution built specifically for your facility.',
		productImg: 'https://exoft.net/landing/healthcare-software-development.webp',
		datePublished: '2024-12-01'
	},
	{
		icon: 'slava',
		text: "Exoft is passionate about what they do. <br><br> They have successfully completed the first phase of the project and is working on the second phase of the migration. Collaborating with the internal team, the vendor works on the deliverables with transparency and dedication. <br><br> They listen to the client's ideas and ensure a high-quality end product.",
		name: 'Slava',
		jobTitle: 'CTO at ANALEC Infotech Private',
		productName: 'CRM Software Development',
		productDescription:
			'Commission our expert CRM software development services to improve customer relationship management, maximize sales, and streamline workflows.',
		productImg: 'https://exoft.net/landing/custom-crm-development-services.webp',
		datePublished: '2024-03-13'
	},
	{
		icon: 'fred',
		text: 'The team was very skilled and well-versed in state-of-the-art technologies.<br><br>Throughout the project, I had an impression that they not only perform the assigned tasks but are constantly and creatively thinking about how to improve our project beyond what we have asked for.<br><br>Outstanding quality of work provided at the agreed upon deadlines. On a personal level, very friendly people',
		name: 'Fred',
		jobTitle: 'CTO at Online Marketplace',
		productName: 'Platform Development',
		productDescription:
			'Partner with our platform development company to turn your product idea into an' +
			' innovative digital solution. Learn more about our expertise.',
		productImg: 'https://exoft.net/landing/platform-development.webp',
		datePublished: '2024-07-05'
	}
];

export const whyUs: BasicContent[] = [
	{
		iconName: '?',
		title: 'Strong expertise',
		content:
			'Our dedicated development team consists of experienced tech savvies truly focused on the work they do. We use best practices and systematic approach, know industry trends, and constantly improve.'
	},
	{
		iconName: '?',
		title: 'Comprehensive approach',
		content:
			'To offer the best possible solution, our software development firm analyzes the client’s business goals and the main idea behind a product before writing the first line of code.'
	},
	{
		iconName: '?',
		title: 'Flexible cooperation model',
		content:
			'We can adapt our software development process to the project needs and allow our clients to choose a cooperation model that suits their financial capabilities best.'
	},
	{
		iconName: '?',
		title: 'Fair pricing',
		content:
			'As a trusted software development partner, Exoft offers supreme-quality services at a reasonable price and tries to increase the project’s cost-effectiveness in every single case.'
	},
	{
		iconName: '?',
		title: 'Client-centric attitude',
		content:
			'For our software development company, client satisfaction is the priority. That is why we strive to exceed the initial expectations in many ways.'
	},
	{
		iconName: '?',
		title: 'Transparency',
		content:
			'We believe that trust is the foundation for success. Our clients have full access to their software engineering projects and can make sure that everything goes smoothly at any time.'
	}
];

export const vacancies: Vacancy[] = [
	{
		position: 'Senior Java Developer (Python)',
		category: 'Engineering',
		occupancy: 'Full-time',
		url: '/java-developer/',
		location: 'Lviv, Ukraine',
		jobOverview:
			'We are looking for a Senior Java Developer (Python) to join our team and work on an innovative application that handles the measurement of water resources, visualizes data, and generates work orders for maintenance tasks. The ideal candidate will have expertise in both Java and Python, with a strong focus on backend development and working with time-series data and engineering problems.',
		responsibilities: [
			'Develop and maintain backend services using Java (Spring Boot, Hibernate, RESTful APIs).',
			'Integrate and manipulate time-series and engineering data for sensor readings and equipment maintenance tasks.',
			'Work with databases to store and manage large datasets effectively.',
			'Utilize Python and data science tools (such as Pandas, NumPy, etc.) to analyze and process data.',
			'Collaborate with cross-functional teams to design, implement, and optimize data-driven solutions.',
			'Implement and manage CI/CD pipelines to streamline development and deployment processes.',
			'Ensure high code quality, maintainability, and scalability through best practices.',
			'Solve mathematical and engineering problems related to time-series data.'
		],
		requirements: [
			'4+ years of experience as a Java Engineer.',
			'Strong proficiency in Java (Spring Boot, Hibernate, RESTful APIs).',
			'Experience with databases (SQL, NoSQL, etc.).',
			'Proficiency with CI/CD pipelines.',
			'Strong skills in Python and data science tools (Pandas, NumPy, etc.).',
			'Experience working with time-series data, engineering data, and mathematical problems.',
			'Upper-Intermediate English level for effective communication.'
		],
		benefits: [
			'Probation period – 3 (three) month',
			'Paid vacation — 18 days + 1 day for each year with us',
			'Paid sick leave (10 days of your base year)',
			'COVID-19 sick leave and treatment',
			'Wellness program (sport, healthcare)',
			'50% compensation for educational matters',
			'Free English courses;',
			'Teambuildings and corporate events'
		]
	},
	{
		position: 'Senior Node.js Developer (with knowledge of React)',
		category: 'Engineering',
		occupancy: 'Full-time',
		url: '/node-react-fullstack/',
		location: 'Lviv, Ukraine',
		jobOverview:
			'We are seeking a Senior Node.js Developer (with knowledge of React) to lead the development of a worker-matching web application. This role requires a deep understanding of backend development with Node.js while also having strong expertise in React for frontend integration. You will be responsible for designing scalable, high-performance APIs and ensuring a seamless user experience.',
		responsibilities: [
			'Develop and maintain backend services using Node.js and Express.js to build scalable RESTful APIs.',
			'Implement dynamic and interactive frontend components using React, React Hooks, Context API, and Redux.',
			'Design and manage MongoDB databases, ensuring efficient queries and optimized data structures.',
			'Utilize RabbitMQ to implement messaging and event-driven architecture.',
			'Write clean, maintainable, and efficient code using TypeScript.',
			'Manage and optimize CI/CD pipelines to streamline development and deployment processes.',
			'Work with cloud platforms to deploy and manage scalable applications.',
			'Collaborate with cross-functional teams, including designers, product managers, and backend developers.',
			'Mentor junior developers and contribute to architectural decisions.'
		],
		requirements: [
			'5+ years of commercial experience with Node.js (Express.js, RESTful APIs).',
			'Solid experience with React (React Hooks, Context API, Redux).',
			'Strong knowledge of MongoDB and database design principles.',
			'Experience working with RabbitMQ for messaging and event-driven architecture.',
			'Strong proficiency in TypeScript.',
			'Familiarity with CI/CD pipelines and cloud platforms.',
			'Upper-Intermediate English level or higher for effective communication.'
		],
		benefits: [
			'Probation period – 3 (three) month',
			'Paid vacation — 18 days + 1 day for each year with us',
			'Paid sick leave (10 days of your base year)',
			'COVID-19 sick leave and treatment',
			'Wellness program (sport, healthcare)',
			'50% compensation for educational matters',
			'Free English courses;',
			'Teambuildings and corporate events'
		]
	},
];

export const technologysStack: object = [
	{
		title: 'Back-end',
		iconName: 'lamp',
		stack: [
			{
				title: '.NET',
				iconName: 'dotNet',
				url: '/net-software-development-company/'
			},
			{
				title: 'Java',
				iconName: 'java',
				url: '/java-development-services/'
			}
		]
	},
	{
		title: 'Front-end',
		iconName: 'webDevelopment',
		stack: [
			{
				title: 'Angular',
				iconName: 'angular',
				url: '/angular-development-services/'
			},
			{
				title: 'ReactJS',
				iconName: 'react',
				url: '/reactjs-development-services/'
			},
			{
				title: 'Vue.js',
				iconName: 'vueJs',
				url: '/vue-js-development-services/'
			}
		]
	},
	{
		title: 'Mobile',
		iconName: 'mobileDevelopment',
		stack: [
			{
				title: 'Android',
				iconName: 'android',
				url: '/mobile-development/'
			},
			{
				title: 'iOS',
				iconName: 'ios',
				url: '/mobile-development/'
			},
			{
				title: 'Xamarin',
				iconName: 'xamarin',
				url: '/xamarin-app-development-services/'
			}
		]
	}
];
