export enum BlogCategoryEnum {
  'Healthcare' = 0,
  'Logistics' = 1,
  'Finance' = 2,
  'Construction' = 3,
  'Media & Entertainment' = 4,
  'Travel & Hospitality' = 5,
  'Energy & Utilities' = 6,
  'Education' = 7,
  'Agriculture' = 8,
  'GreenTech' = 9,
  'Pharma' = 10,
  'PropTech' = 11,
  'Web' = 12,
  'Mobile' = 13,
  'UI/UX Design' = 14,
  'QA' = 15,
  'Business app' = 16,
  'Platform development' = 17,
  'Outsourcing' = 18,
  'Team Extension' = 19,
  'Trends' = 20,
  'App modernization' = 21
}
